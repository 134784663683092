import React from 'react';
import { IMAGES } from '../../../constants/images';
import { Typography } from '@mui/material';
import { RestartScenarioButton } from '../restart-scenario-button';
import { ResetScoreButton } from '../reset-score-button';
import { SHOW_RESET_LEARNING_PROGRESS } from 'common-frontend/constants/env';
import { versionsFormater } from 'common-frontend/utils/formatters';

export const Footer = () => {
	return (
		<footer>
			<div className="footer-logo-container">
				<img src={IMAGES.footer} alt="logo" />
				<div className="buttons-container">
					<RestartScenarioButton color="white" />
					{SHOW_RESET_LEARNING_PROGRESS && <ResetScoreButton color="white" />}
				</div>
			</div>
			<div className="versions-container">
				<VersionsMemo />
			</div>
		</footer>
	);
};

const VersionsMemo = React.memo(function VersionsMemo() {
	return <Typography variant="caption">{versionsFormater()}</Typography>;
});
