import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { MOCK_NAME, URLS } from '../constants/env';
import { useStores } from './store-provider';
import { postAsync } from '../utils/fetch-helper';
import { alertUser } from '../utils/alert';
import { useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { setSessionStore, getSessionStore } from '../utils/session-store';
import { useIdleSignOut } from '../utils/hooks';

export const Auth = observer(() => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { PopupStore, AuthStore } = useStores();
	const { closePopup, openPopup } = PopupStore;
	const { setAuthProfile } = AuthStore;

	/**
	 * Auto logout if user not active more 15 minutes
	 */
	useIdleSignOut();

	useEffect(() => {
		/**
		 * After auto sign-out redirect comeback, if session was closed or token expired, then localStore include message
		 * More info: auth-handlers.js -> refreshToken function -> status 400
		 */
		if (localStorage.getItem('post-logout-message')) {
			const [message, type] = localStorage.getItem('post-logout-message').split('|');
			localStorage.removeItem('post-logout-message');
			alertUser(message, type);
		}
		/**
		 * If user already signed in, then we can get user profile from session storage
		 */
		if (getSessionStore(`preferred_username-${MOCK_NAME}`) && getSessionStore(`sub-${MOCK_NAME}`)) {
			setAuthProfile({
				sub: getSessionStore(`sub-${MOCK_NAME}`),
				preferred_username: getSessionStore(`preferred_username-${MOCK_NAME}`),
			});
		}
		/**
		 * After sign-in redirect comeback, code and state can be taken from URL
		 */
		const code = searchParams.get('code');
		const state = searchParams.get('session_state');

		if (code && state) {
			/**
			 * Exchange code and state for token on server side
			 */
			openPopup();
			postAsync('/token', { state, code, redirectUri: URLS.app }, [], true)
				.then((response) => {
					if (response.status !== 200) {
						throw new Error('Obtaining auth token failed');
					}
					return response.json(); // DTO: access_token: string, refresh_token: string, id_token: string, token_type: string
				})
				.then((p) => {
					setSessionStore(`access_token-${MOCK_NAME}`, p.access_token);
					setSessionStore(`refresh_token-${MOCK_NAME}`, p.refresh_token);
					setSessionStore(`id_token-${MOCK_NAME}`, p.id_token);

					const decoded = jwtDecode(p.access_token); // DTO: preferred_username: string, sub: string
					setSessionStore(`preferred_username-${MOCK_NAME}`, decoded.preferred_username);
					setSessionStore(`sub-${MOCK_NAME}`, decoded.sub);
					setAuthProfile({
						sub: decoded.sub,
						preferred_username: decoded.preferred_username,
					});
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setSearchParams({});
					closePopup();
				});
		}

		return () => closePopup();
		// setSearchParams in the dependency array retriggers useEffect when tab is changed, and it creates a chain of unwanted rerenders
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [closePopup, openPopup, searchParams, setAuthProfile]);
});
