import React from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

export const AcceptPopup = ({ id, isOpen, title, text, confirmText, onClose, action, isWarning, isDanger, closeIcon }) => {
	const { t } = useTranslation();
	return (
		<Dialog id={id + '-popup'} open={isOpen}>
			<div className="accept-popup">
				{title && <Typography variant="h5">{title}</Typography>}
				{isWarning && (
					<Typography color="secondary" className="mb-16 mt-16">
						<b>{t('warning')}!</b>
					</Typography>
				)}
				{text && <Typography className="mt-8 mb-16">{text}</Typography>}
				{confirmText && (
					<Typography color="secondary" className="mt-16">
						<b>{confirmText}</b>
					</Typography>
				)}
				<div className="accept-popup-buttons mt-16">
					<Button
						id={id + '-yes'}
						fullWidth
						variant="contained"
						size="large"
						onClick={action}
						color={isDanger ? 'error' : 'success'}
					>
						{t('yes')}
					</Button>
					<Button id={id + '-no'} fullWidth variant="contained" size="large" onClick={onClose}>
						{t('no')}
					</Button>
				</div>
				{closeIcon && (
					<Button className="accept-popup__close-icon" onClick={onClose}>
						<CloseIcon />
					</Button>
				)}
			</div>
		</Dialog>
	);
};
