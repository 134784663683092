import { createTheme } from '@mui/material';

export const THEME = createTheme({
	palette: {
		primary: {
			light: '#e0e0e0',
			main: '#b1b1b1',
			dark: '#b1b1b1',
			text: '#fff',
			contrastText: '#fff',
		},
		secondary: {
			light: '#c0003c',
			main: '#c0003c',
			dark: '#c0003c',
			text: '#4b4b4b',
		},
		blue: {
			background: '#3e7cb1',
			border: '#2e6ca1',
		},
		gray: {
			background: '#b1b1b1',
			border: '#dfdfdf',
		},
		white: {
			main: '#fff',
			contrastText: '#b1b1b1',
		},
	},
	typography: {
		fontSize: '17px',

		h1: {
			fontSize: '30px',
			color: '#c0003c',
		},
		h2: {
			fontSize: '27px',
			color: '#c0003c',
		},
		h3: {
			fontSize: '19px',
		},
		h4: {
			fontSize: '27px',
			color: '#fff',
		},
		h5: {
			fontSize: '27px',
		},
	},
	variables: {
		maxWidth: '1100px',
	},
});
