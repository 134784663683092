import { MOCK_NAME } from '../constants/env';
import { getAsync, postAsync, deleteAsync, getResponseMsg, putAsync } from '../utils/fetch-helper';
import { STORES } from './shared-stores';
import { alertUser } from '../utils/alert';

export const GET_USER = async () => {
	const {
		UserStore: { setUser, setUserStatusCode, getUserStatusCode, setUserJustBoarded },
		QrStore: { closeQr },
	} = STORES;
	const previousUserStatusCode = getUserStatusCode();
	const urlPart = MOCK_NAME === 'education' || MOCK_NAME === 'chamber' ? MOCK_NAME : 'user';
	const response = await getAsync(`/${urlPart}`, [403, 410]);

	setUserStatusCode(response.status.toString());

	if (response.status === 200) {
		const data = await response.json();
		setUser(data);
		closeQr();

		if (setUserJustBoarded && previousUserStatusCode && previousUserStatusCode !== '200') {
			setUserJustBoarded(true);
		}

		return await SYNC_LANGUAGE();
	}

	setUser(null);
};

export const GET_QR = async () => {
	const {
		UserStore: { setUserStatusCode },
		QrStore: { setQrUrl, setQrValidity, closeQr },
	} = STORES;
	const response = await getAsync('/qrcode');

	if (response.status === 200) {
		const data = await response.json();
		setQrUrl(`nmshd://tr#${data.truncatedReference}`);
		setQrValidity(data.expiresAt);
		setUserStatusCode('403'); // predict, relationship created on BE side, next getUser() will return 403
		return;
	}

	closeQr();
};

export const REMOVE_RELATIONSHIP = async () => {
	const {
		UserStore: { setUser, setUserStatusCode },
	} = STORES;
	const response = await deleteAsync('/delete-relationship');

	if (response.status === 200) {
		setUser(null);
		setUserStatusCode('410');
	}
};

export const UPLOAD_DOCUMENT = async (uploadingFile) => {
	const formData = new FormData();
	formData.append('file', uploadingFile);
	await postAsync('/upload-file', formData);
};

export const DOWNLOAD_DOCUMENT = async (document) => {
	const response = await getAsync(`/documents/download/${document.uuid}`);

	if (response.status === 200) {
		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob, {
			type: document.mimeType,
		});
		const link = window.document.createElement('a');

		link.href = url;
		link.download = document.title ? `${document.title}.pdf` : document.filename;
		link.click();
		link.remove();
		window.URL.revokeObjectURL(url);
	}
};

export const SYNC_LANGUAGE = async () => {
	await getAsync('/i18n');
};

export const SEND_MESSAGE = async (payload, onSuccess) => {
	const response = await postAsync('/send/message', payload);

	if (response.status === 200) {
		onSuccess();
		GET_HISTORY();
	} else {
		const msg = await getResponseMsg(response);
		msg && alertUser(msg, 'error');
	}
};

export const GET_HISTORY = async () => {
	const {
		MessagesStore: { setMessages },
	} = STORES;

	const response = await getAsync('/history');

	if (response.status === 200) {
		const data = await response.json();

		setMessages(data);
	} else {
		const msg = await getResponseMsg(response);
		msg && alertUser(msg, 'error');
	}
};

export const GET_GROUPS = async (page = 1) => {
	const {
		GroupsStore: { setGroups, pageSize },
	} = STORES;

	const response = await getAsync(`/groups/owned?page=${page - 1}&size=${pageSize}`);

	if (response.status === 200) {
		const data = await response.json();

		setGroups(data.content, data.totalPages);
	} else {
		const msg = await getResponseMsg(response);
		msg && alertUser(msg, 'error');
	}
};

export const DELETE_GROUP = async (groupId) => {
	const {
		GroupsStore: { setSelectedGroup, currentPage, groups, setCurrentPage },
	} = STORES;

	const response = await deleteAsync(`/groups/${groupId}`);

	if (response.status === 200) {
		const deletedWasTheOnlyOneOnPage = groups.length === 1;
		const newCurrentPage = deletedWasTheOnlyOneOnPage ? currentPage - 1 : currentPage;

		await GET_GROUPS(newCurrentPage);
		setCurrentPage(newCurrentPage);
		setSelectedGroup(null);
	} else {
		const msg = await getResponseMsg(response);
		msg && alertUser(msg, 'error');
	}
};

export const SAVE_GROUP = async (group, onSuccess) => {
	const {
		GroupsStore: { setSelectedGroup, getGroupById, currentPage, groups, pageSize, setCurrentPage },
	} = STORES;

	const response = await postAsync('/groups', group);

	if (response.status === 200) {
		const { id } = await response.json();
		const createdIsOnNextPage = groups.length === pageSize;
		const newCurrentPage = createdIsOnNextPage ? currentPage + 1 : currentPage;

		await GET_GROUPS(newCurrentPage);
		setCurrentPage(newCurrentPage);
		setSelectedGroup(getGroupById(id));
		onSuccess();
	} else {
		const msg = await getResponseMsg(response);
		msg && alertUser(msg, 'error');
	}
};

export const UPDATE_GROUP = async (updatedGroup, onSuccess) => {
	const {
		GroupsStore: { setSelectedGroup, currentPage },
	} = STORES;

	const response = await putAsync(`/groups`, updatedGroup);

	if (response.status === 200) {
		await GET_GROUPS(currentPage);

		setSelectedGroup(updatedGroup);
		onSuccess();
	} else {
		const msg = await getResponseMsg(response);
		msg && alertUser(msg, 'error');
	}
};

export const SEARCH_USERS = async (searchValue, paginationModel, sortModel, setData, setTotalRows) => {
	const sortString = sortModel.map((s) => `&sort=${s.field},${s.sort}`).join();
	const response = await getAsync(
		`/users/search?value=${searchValue}&page=${paginationModel.page}&size=${paginationModel.pageSize}${sortString}`,
	);

	if (response.status === 200) {
		const data = await response.json();

		setData(data.content);
		setTotalRows(data.totalElements);
	} else {
		const msg = await getResponseMsg(response);
		msg && alertUser(msg, 'error');
	}
};
