import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './header';
import { Footer } from './footer';

export const MainLayout = () => {
	return (
		<>
			<div className="page-wrapper">
				<Header />
				<Outlet />
			</div>
			<Footer />
		</>
	);
};
