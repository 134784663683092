import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { IMAGES } from '../../../constants/images';
import { useTranslation } from 'react-i18next';
import { SYNC_LANGUAGE } from 'common-frontend/services/api-calls';
import { observer } from 'mobx-react';
import { useStores } from 'common-frontend/components/store-provider';
import { PATHS } from '../../../constants/paths';

export const Header = observer(() => {
	const { t, i18n } = useTranslation();
	const { AuthStore, UserStore } = useStores();
	const { authProfile } = AuthStore;
	const { user } = UserStore;

	const switchLanguage = useCallback(
		(lang) => {
			i18n.changeLanguage(lang); // en || de
			user && SYNC_LANGUAGE();
		},
		[user, i18n],
	);

	return (
		<>
			<div className="logo-wrapper">
				<div className="logo-container">
					<img src={IMAGES.logo} alt="logo" />
				</div>
			</div>
			<div className="nav-container">
				<nav className="nav">
					<NavLink id="home-link" to={PATHS.home}>
						{t('nav.home')}
					</NavLink>
					<NavLink id="studies-link" to={PATHS.studies}>
						{t('nav.studies')}
					</NavLink>
					<div className="language-switch-container">
						<a
							className="language-switch"
							translate="no"
							onClick={() => {
								switchLanguage('de');
							}}
						>
							DE
						</a>
						<a
							className="language-switch"
							translate="no"
							onClick={() => {
								switchLanguage('en');
							}}
						>
							EN
						</a>
					</div>
					<NavLink id="user-link" to={PATHS.user} disabled={!authProfile}>
						{t('nav.user')}
					</NavLink>
				</nav>
			</div>
		</>
	);
});
