import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from './store-provider';
import { GET_USER, GET_QR } from '../services/api-calls';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { Button, CircularProgress, Dialog, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { AcceptPopup } from './accept-popup';
import { signIn, signOut } from '../utils/auth-handlers';
import { alertUser } from '../utils/alert';

export const UserBar = observer(() => {
	const { t } = useTranslation();
	const { pathname, key } = useLocation();
	const { PopupStore, UserStore, AuthStore, QrStore } = useStores();
	const { closePopup, openPopup } = PopupStore;
	const { user, getUserStatusCode } = UserStore;
	const { authProfile } = AuthStore;
	const { qrIsOpen, openQr, closeQr, qrUrl, setQrUrl, qrValidity } = QrStore;
	const userStatusCode = getUserStatusCode();

	const [acceptLogoutOpen, setAcceptLogoutOpen] = useState(false);

	const intervalRef = useRef(0);
	const locationKey = useRef(key);

	const getQrHandler = useCallback(() => {
		setQrUrl('');
		openQr();
		GET_QR();
	}, [openQr, setQrUrl]);

	const userCheck = useCallback(() => {
		console.info('Check user first time');
		openPopup();
		GET_USER().finally(() => closePopup());
	}, [openPopup, closePopup]);

	const userCheckLoop = useCallback(() => {
		clearInterval(intervalRef.current);
		console.info('Check user loop started');
		intervalRef.current = setInterval(() => {
			GET_USER();
			console.info('Check user');
		}, 15 * 1000);
	}, []);

	useEffect(() => {
		if (!user && authProfile && userStatusCode !== '200') {
			/**
			 * user already checked and not found or not onboarded
			 */
			if (userStatusCode === '410' || userStatusCode === '403') {
				userCheckLoop();
				return;
			}
			/**
			 * first user check
			 */
			userCheck();
			return;
		}

		clearInterval(intervalRef.current);
		console.info('Check user loop finished!');
	}, [user, authProfile, userStatusCode, userCheck, userCheckLoop]);

	useEffect(() => {
		if (pathname === '/user' && locationKey.current !== key) {
			GET_USER();
		}
		locationKey.current = key;
	}, [pathname, key]);

	useEffect(() => {
		return () => {
			clearInterval(intervalRef.current);
			console.info('Check user loop finished, component destroyed!');
			closePopup();
		};
	}, [closePopup]);

	return (
		<div className="user-bar">
			{authProfile ? (
				<>
					<span className="user-bar__welcome">
						{t('welcome')}
						<span className="user-bar__user-name">{authProfile.preferred_username}</span>
					</span>
					{!user && (
						<button id="qr-button" className="user-bar__qr-button" onClick={getQrHandler} data-testid="qr-button">
							<QrCodeIcon data-testid="qr-code-icon" />
						</button>
					)}
					<div className="delimiter"></div>
					<Link id="auth-link" onClick={() => setAcceptLogoutOpen(true)}>
						{t('logout')}
					</Link>
				</>
			) : (
				<Link id="auth-link" onClick={signIn}>
					{t('login')}
				</Link>
			)}
			<MemoQrDialog closeQr={closeQr} qrUrl={qrUrl} qrIsOpen={qrIsOpen} qrValidity={qrValidity} t={t} />
			<AcceptPopup
				id="accept-popup"
				title={t('logout-message')}
				isDanger={true}
				isOpen={acceptLogoutOpen}
				onClose={() => setAcceptLogoutOpen(false)}
				action={signOut}
			/>
		</div>
	);
});

const MemoQrDialog = React.memo(function MemoQrDialog({ qrIsOpen, qrUrl, closeQr, qrValidity, t }) {
	const mobileLayout = isMobile || isTablet;

	const handleLinkClipboardClick = () => {
		navigator.clipboard.writeText(qrUrl);
		alertUser(t('nav-user.clipboard-success'), 'success', 3 * 1000);
	};

	const renderDesktopQr = (qrUrl) =>
		!qrUrl ? (
			<CircularProgress size={64} data-testid="qr-loader" />
		) : (
			<>
				<QRCode id="qr-image" size={200} value={qrUrl} viewBox="0 0 200 200" />
				<Link id="qr-link" className="inactive-link" to={qrUrl}>
					{qrUrl}
				</Link>
				<a id="clipboard-link" href="#" onClick={handleLinkClipboardClick}>
					{t('nav-user.clipboard')}
				</a>
				<Timer qrValidity={qrValidity} t={t} />
			</>
		);

	return (
		<Dialog id="qr-popup" open={qrIsOpen} onClose={closeQr}>
			<div className={`qr-container ${mobileLayout ? 'qr-container--mobile' : ''}`}>
				{mobileLayout ? (
					<>
						<Typography className="connect-app-text" variant="p">
							{t('nav-user.connect-app-text')}
						</Typography>
						{!qrUrl ? (
							<CircularProgress size={36.5} />
						) : (
							<Button component={Link} id="connect-app-button" variant="contained" to={qrUrl}>
								{t('nav-user.connect-app-button')}
							</Button>
						)}
					</>
				) : (
					renderDesktopQr(qrUrl)
				)}
				<div id="qr-tooltip-anchor" className="qr-tooltip-anchor" />
			</div>
		</Dialog>
	);
});

const Timer = React.memo(function Timer({ qrValidity, t }) {
	const calculateTimeLeft = (validity) => {
		const targetTime = new Date(validity);
		const now = new Date();
		const difference = +targetTime - +now;
		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(qrValidity));

	useEffect(() => {
		setTimeLeft(calculateTimeLeft(qrValidity));
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft(qrValidity));
		}, 1000);

		return () => clearInterval(timer);
	}, [qrValidity]);

	return (
		<div className="timer">
			{timeLeft.minutes || timeLeft.seconds ? (
				<span>
					{timeLeft.minutes}:{timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
				</span>
			) : (
				<span>{t('time-is-up')}</span>
			)}
		</div>
	);
});
