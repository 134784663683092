import React, { useCallback, useState, useMemo } from 'react';
import { Button } from '@mui/material';
import { useStores } from 'common-frontend/components/store-provider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionsPopup } from 'common-frontend/components/actions-popup';
import { observer } from 'mobx-react';
import { RESET_SCORE } from '../../services/api-calls';
import { COURSES } from '../../constants/courses';
import { PATHS } from '../../constants/paths';

export const ResetScoreButton = observer(({ ...props }) => {
	const { WalletStore, UserStore } = useStores();
	const { getScore } = WalletStore;
	const { user } = UserStore;
	const navigate = useNavigate();

	const [localLoader, setLocalLoader] = useState(false);
	const [resetScorePopup, setResetScorePopup] = useState(false);
	const { t } = useTranslation();

	const resetScore = useCallback(
		(course) => {
			setLocalLoader(true);
			setResetScorePopup(false);

			RESET_SCORE(course).finally(() => {
				setLocalLoader(false);
				navigate(PATHS.studies);
			});
		},
		[navigate],
	);

	const actions = useMemo(() => {
		const array = COURSES.filter((course) => getScore(course) > 0).map((course) => ({
			id: course,
			name: t('footer-user.reset-score.reset-course', {
				course: t(`courses.${course}.name`),
			}),
			func: () => {
				resetScore(course);
			},
			isDanger: true,
		}));

		if (array.length === COURSES.length) {
			array.push({
				id: 'all',
				name: t('footer-user.reset-score.reset-all'),
				func: () => {
					resetScore('all');
				},
				isDanger: true,
			});
		}

		return array;
	}, [getScore, resetScore, t]);

	return (
		user &&
		WalletStore.coursesInProgress && (
			<>
				<Button
					id="reset-score-button"
					disabled={localLoader}
					variant="contained"
					onClick={() => setResetScorePopup(true)}
					{...props}
				>
					{t('footer-user.reset-score.button')}
				</Button>
				<ActionsPopup
					id="reset-score-popup"
					title={t('footer-user.reset-score.button')}
					text=""
					isOpen={resetScorePopup}
					onClose={() => setResetScorePopup(false)}
					actions={actions}
				/>
			</>
		)
	);
});
