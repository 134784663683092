import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { STORES } from './shared-stores';
import { hydrateStore } from 'mobx-persist-store';

export const initI18n = (en, de) => {
	const resources = {
		en: { translation: en },
		de: { translation: de },
	};

	i18n.use(initReactI18next).init({
		resources,
		lng: localStorage.getItem('language') || 'de',
		fallbackLng: 'de',
		keySeparator: '.',
		interpolation: {
			escapeValue: false,
		},
	});

	i18n.on('languageChanged', (language) => {
		localStorage.setItem('language', language);
	});

	window.addEventListener('storage', ({ key, newValue }) => {
		if (key === 'language') {
			i18n.changeLanguage(newValue);
			return;
		}

		const storeKey = Object.keys(STORES).find((store) => STORES[store].storageKey === key);
		if (storeKey) {
			hydrateStore(STORES[storeKey]);
		}
	});
};
