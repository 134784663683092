import { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStores } from '../components/store-provider';
import { signOut } from '../utils/auth-handlers';
import { useIdleTimer } from 'react-idle-timer';

export function useToggle(init) {
	const [value, setValue] = useState(init);

	return [
		value,
		() => {
			setValue((prev) => !prev);
		},
	];
}

export function useLoading(apiFunc) {
	const [isInProgress, setIsInProgress] = useState(false);

	const callApi = useCallback(
		async (...params) => {
			setIsInProgress(true);

			try {
				await apiFunc(...params);
			} finally {
				setIsInProgress(false);
			}
		},
		[apiFunc, setIsInProgress],
	);

	return [callApi, isInProgress];
}

export const useScreenSize = () => {
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handleResize = () => {
			setScreenSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return screenSize;
};

export const usePrevious = (value) => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

/**
 * react-hook-form with additional helpers for the project
 */
export const useCustomForm = (formKey, yupSchema, externalFormData, defaultValues) => {
	const { t } = useTranslation();
	const {
		FormsStore: { forms, updateForm, deleteForm },
	} = useStores();
	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(yupSchema),
		defaultValues,
	});
	const {
		register,
		formState: { errors, isDirty },
		watch,
		reset,
		control,
		trigger,
	} = form;
	/**
	 * @type {'watch' | 'create' | 'edit'}
	 */
	const [formMode, setFormMode] = useState('create');
	const previousMode = usePrevious(formMode);

	/**
	 * Getting saved data from the external object that is passed to the form
	 */
	useEffect(() => {
		if (externalFormData) {
			reset(externalFormData);
			trigger();
		}

		setFormMode(externalFormData ? 'watch' : 'create');
	}, [externalFormData, reset, trigger]);

	useEffect(() => {
		/**
		 * Getting saved data from the store
		 */
		if (forms[formKey]) {
			reset(forms[formKey].data);
			setFormMode(forms[formKey].mode);
			trigger();
		}

		/**
		 * Putting current form data in the store
		 */
		const { unsubscribe } = watch((value) => {
			if (value && Object.keys(value).length > 0) {
				updateForm(formKey, value, formMode);
			} else {
				deleteForm(formKey);
			}
		});

		return () => unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Reacting to mode changing
	 */
	useEffect(() => {
		if (!previousMode) {
			return;
		}

		if (formMode === 'create' && previousMode !== 'create') {
			reset(yupSchema.getDefault());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formMode]);

	const customRegister = useCallback(
		(name, options) => {
			const registered = register(name, options);
			const errorMessage = errors[name]?.message ?? '';
			/**
			 * For displaying different validation constraints (like max, min, length, etc.) inside error messages
			 * they are put at the end of the i18n key after '|' delimiter
			 * Example:
			 * errorMessage = 'some.translation.key|3'
			 * ↓
			 * helperText = 'This field should have at least 3 characters
			 */
			const [errorKey, validationParameter] = errorMessage.split('|');

			if (options?.controlled) {
				delete registered.ref;
				registered.control = control;
			}

			return {
				...registered,
				id: `input-${name}`,
				disabled: formMode === 'watch',
				/**
				 * For each MUI input a set of error and helperText is created to show error messages
				 */
				error: !!errors[name],
				helperText: t(errorKey, { value: validationParameter }),
				/**
				 * MUI has a bug: when setValue is used it won't shrink the label inside input, so label and value overlap
				 */
				InputLabelProps: { shrink: externalFormData ? !!externalFormData : undefined },
			};
		},
		[errors, register, formMode, control, t, externalFormData],
	);

	/**
	 * Cancel any activity on the form
	 */
	const cancel = useCallback(() => {
		if (isDirty && externalFormData) {
			reset(externalFormData);
		}

		setFormMode('watch');
	}, [externalFormData, isDirty, reset]);

	return { ...form, register: customRegister, formMode, setFormMode, cancel };
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const useIdleSignOut = (minutes = 15) => {
	const { AuthStore } = useStores();
	const { authProfile } = AuthStore;

	const handleIdle = useCallback(async () => {
		// Locking with sleep because other tabs can't sing out at the same time (aai bug)
		await navigator.locks.request('sing_out', async () => {
			await sleep(500);
			signOut();
		});
	}, []);

	useIdleTimer({
		timeout: minutes * 60 * 1000,
		onIdle: handleIdle,
		disabled: !authProfile,
		crossTab: true,
		throttle: 1000,
	});
};
