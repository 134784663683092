export let STORES;

export const setSharedStores = (stores) => {
	STORES = stores;
};

export const resetStores = (ignoredStores = []) => {
	Object.keys(STORES).forEach((store) => {
		if (!ignoredStores.includes(store) && store?.reset) {
			store.reset();
		}
	});
};
