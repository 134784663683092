export const URLS = {
	idp: process.env.REACT_APP_SSO_URL,
	app: process.env.REACT_APP_SELF_URL,
	api: process.env.REACT_APP_BACKEND_API_URL,
	university: process.env.REACT_APP_UNIVERSITY_URL,
	betaTest: process.env.REACT_APP_BETA_TEST_URL,
};

export const CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID;

export const MOCK_NAME = process.env.REACT_APP_MOCK_NAME;

export const APP_SUFFIX = process.env.REACT_APP_URLSUFFIX || `/${MOCK_NAME}`;

export const APP_VERSIONS = process.env.REACT_APP_VERSIONS || ''; // MVP, MOCK_FE, FE, MOCK_BE, BE || Build time Date

export const FAKE_AUTH = process.env.REACT_APP_NO_AUTH === 'true';

export const SHOW_RESET_LEARNING_PROGRESS = process.env.REACT_APP_SHOW_RESET_LEARNING_PROGRESS === 'true';
